import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Chip,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import Layout from "../components/layout/Layout";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import { useEffect } from "react";
import weatherAppImage from "../assets/weather_app.png";
import philoImage from "../assets/philo.jpg";

const projects = [
  {
    title: "Weather App",
    description:
      "Weather app that provides current weather information for any city.",
    image: weatherAppImage,
    technologies: ["Javascript", "HTML", "CSS"],
    demoLink: "https://karakuscem.github.io/Weather-App/",
    codeLink: "https://github.com/karakuscem/Weather-App",
  },
  {
    title: "Philosophers",
    description: "Simple solution for philosophers problem written in C",
    image: philoImage,
    technologies: ["C"],
    demoLink: "#",
    codeLink: "https://github.com/karakuscem/philo",
  },
  {
    title: "API Gateway Service",
    description:
      "A microservice-based API gateway handling authentication and request routing.",
    image: "https://via.placeholder.com/400x200",
    technologies: ["Spring Cloud", "Docker", "Redis"],
    demoLink: "#",
    codeLink: "#",
  },
  {
    title: "Analytics Dashboard",
    description:
      "Real-time analytics dashboard with interactive visualizations.",
    image: "https://via.placeholder.com/400x200",
    technologies: ["Angular", "D3.js", "Spring Boot"],
    demoLink: "#",
    codeLink: "#",
  },
];

const Projects = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
      },
    },
  };

  return (
    <Layout title="Projects">
      <Box>
        <Container maxWidth="lg">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <Grid
              container
              spacing={4}
              component={motion.div}
              variants={containerVariants}
            >
              {projects.map((project, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={index}
                  sx={{
                    display: "flex",
                  }}
                >
                  <motion.div variants={itemVariants} style={{ width: "100%" }}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        overflow: "hidden",
                        "&:hover": {
                          "& .project-image": {
                            transform: "scale(1.05)",
                          },
                          "& .project-overlay": {
                            opacity: 0.7,
                          },
                        },
                        minHeight: "500px",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          paddingTop: "56.25%",
                          minHeight: "225px",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={project.image}
                          alt={project.title}
                          className="project-image"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            transition: "transform 0.3s ease-in-out",
                          }}
                        />
                        <Box
                          className="project-overlay"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`,
                            opacity: 0.5,
                            transition: "opacity 0.3s ease-in-out",
                          }}
                        />
                      </Box>

                      <CardContent sx={{ flexGrow: 1, zIndex: 1 }}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          sx={{
                            fontWeight: 600,
                            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                        >
                          {project.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          paragraph
                        >
                          {project.description}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                            mt: 2,
                          }}
                        >
                          {project.technologies.map((tech, idx) => (
                            <Chip
                              key={idx}
                              label={tech}
                              size="small"
                              sx={{
                                background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                                color: "white",
                                "&:hover": {
                                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                                },
                              }}
                            />
                          ))}
                        </Box>
                      </CardContent>

                      <CardActions sx={{ p: 2, pt: 0 }}>
                        <Button
                          startIcon={<FaExternalLinkAlt />}
                          href={project.demoLink}
                          target="_blank"
                          variant="contained"
                          sx={{
                            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                            "&:hover": {
                              background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                            },
                          }}
                        >
                          Live Demo
                        </Button>
                        <Button
                          startIcon={<FaGithub />}
                          href={project.codeLink}
                          target="_blank"
                          variant="outlined"
                          sx={{
                            borderColor: theme.palette.primary.main,
                            color: theme.palette.primary.main,
                            "&:hover": {
                              borderColor: theme.palette.primary.light,
                              color: theme.palette.primary.light,
                            },
                          }}
                        >
                          View Code
                        </Button>
                      </CardActions>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </Container>
      </Box>
    </Layout>
  );
};

export default Projects;
